import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import marked from 'marked'
import { Row, Container, Col, Form, Button } from 'react-bootstrap/'
import TopHeader from "../components/top-header"
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"


const IsveZamanCampaign = ({ intl }) => {

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
  }
  const { namesurname, comp, phone, email, checkboxChecked, answer1, answer2, answer3 } = useState({})

  const desc = "[Retmes Linkedin](https://www.linkedin.com/company/retmes/) sayfasını takip eden ve aşağıdaki sorulara doğru yanıt verenlerin arasından yapacağımız çekiliş ile 5 kişiye [İş ve Zaman Etüdü (Refa Uyumlu) Eğitimi](https://www.cardeacert.com/egitim/is-ve-zaman-etudu-refa-uyumlu-egitimi/) hediye ediyoruz! <br/> <br/> [İş ve Zaman Etüdü (Refa Uyumlu) Eğitimi](https://www.cardeacert.com/egitim/is-ve-zaman-etudu-refa-uyumlu-egitimi/) sertifikalıdır ve **08-09 Mart 2021** tarihlerinde **online olarak** gerçekleştirilecektir. <br/> <br/> Çekilişimize **28 Şubat 2021** saat **23.59**'a kadar katılım sağlayabilirsiniz. Sonuçlar **3 Mart 2021 Çarşamba** günü [Retmes Linkedin](https://www.linkedin.com/company/retmes/) sayfasında açıklanacaktır.  <br/>  <br/> Soruları cevaplarken [OEE Nedir?](https://www.retmes.com/oee-nedir) içeriğimizden yardım alabilirsiniz."
  const descSEO = "Retmes Linkedin sayfasını takip eden ve aşağıdaki sorulara doğru yanıt verenlerin arasından yapacağımız çekiliş ile 5 kişiye İş ve Zaman Etüdü (Refa Uyumlu) Eğitimi hediye ediyoruz!"
  const title = "İş ve Zaman Etüdü Eğitim Kampanyası"
  const question1 = "Bir üretim hattında OEE değerinin **%65,2** olarak elde edilmesi için gereken üretkenlik, kullanılabilirlik ve kalite değerlerinden hangisi doğrudur?"
  const question2 = "X fabrikada, bir vardiyada **üretkenlik değeri: 78**, **kullanılabilirlik değeri: 85** ve **kalite değeri: 90**'dır. OEE hesaplaması yapıldığında ulaşılan değer aşağıdakilerden hangisidir?"
  const question3 = "Y fabrikasının bir üretim hattında, **üretkenlik değeri: 70**, **kullanılabilirlik değeri: 72** ve **kalite değeri: 80**'dir. Bu üretim hattının OEE değerini %50'nin üstüne çıkarabilmek için aşağıdakilerden hangisi veya hangilerini uygulamaları gerekir?"
  const ogImage = "https://res.cloudinary.com/konzek/image/upload/v1611664276/Retmes/campaigns/retmes-og-image-is-ve-zaman_1.jpg"
 

  const info = "Hesaplamalarda; virgülden sonraki 2 basamak, tek basamak olarak yukarı yuvarlanacaktır."

  return (
    <Layout>
      <TopHeader />
      <SEO
        title={title}
        description={descSEO}
        imageURL={ogImage} />
      <section>
        <Container className='kosgeb-lp'>
          <h1>{title}</h1>
          <Row>
            <Col sm className='retmes-lp-ml-col-2'>
              <div dangerouslySetInnerHTML={{ __html: marked(desc) }} />
            </Col>
          </Row>
          <Row>
            <Col sm className='campaign-info'>
              <div dangerouslySetInnerHTML={{ __html: marked(info) }} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Form
            netlify
            noValidate
            validated={validated}
            className='campaign-form'
            onSubmit={handleSubmit}
            name="Retmes_Egitim_IS_VE_ZAMAN_ETUDU"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="Retmes_Customer_Contact" />

            <Form.Group >
              <Form.Label>Kişisel Bilgileriniz</Form.Label>
              <Form.Control required type="text" name="namesurname" value={namesurname} placeholder={intl.formatMessage({ id: "contact_form_name" })} onChange={handleChange} />
            </Form.Group>
            <Form.Group >
              <Form.Control required type="email" name="email" value={email} placeholder={intl.formatMessage({ id: "contact_form_email" })} onChange={handleChange} />
              <Form.Control.Feedback type="invalid"><FormattedMessage id="contact_email_feedback" /></Form.Control.Feedback>
            </Form.Group>
            <Form.Group >
              <Form.Control required type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" value={phone} name="phone" placeholder={intl.formatMessage({ id: "contact_form_phone" })} onChange={handleChange} />
              <Form.Control.Feedback type="invalid"><FormattedMessage id="contact_phone_feedback" /></Form.Control.Feedback>
            </Form.Group>

            <Form.Group >
              <Form.Control type="text" name="comp" placeholder={intl.formatMessage({ id: "contact_form_comp" })} value={comp} onChange={handleChange} />
            </Form.Group>
            <Form.Label>Soru 1</Form.Label>
            <Form.Group controlId="exampleForm.ControlSelect2">
              <h2><div dangerouslySetInnerHTML={{ __html: marked(question1) }} /></h2>
              <Form.Control as="select" required
                value={answer1}
                name={'1. Soru'}
                onChange={handleChange}
              >
                <option>Seçiniz</option>
                <option>75 - 85 - 80</option>
                <option>80 - 84 - 97</option>
                <option>80 - 82 - 95</option>
              </Form.Control>
            </Form.Group>
            <Form.Label>Soru 2</Form.Label>
            <Form.Group controlId="exampleForm.ControlSelect2">
              <h2><div dangerouslySetInnerHTML={{ __html: marked(question2) }} /></h2>
              <Form.Control as="select"
                required
                value={answer2}
                name={'2. Soru'}
                onChange={handleChange}
              >
                <option>Seçiniz</option>
                <option>%55,3</option>
                <option>%58,5</option>
                <option>%59,7</option>
              </Form.Control>
            </Form.Group>
            <Form.Label>Soru 3</Form.Label>
            <Form.Group controlId="exampleForm.ControlSelect2"

            >
              <h2><div dangerouslySetInnerHTML={{ __html: marked(question3) }} /></h2>
              <ul>
                <li>
                  I- Üretkenlik değerinde 80'e, kalite değerinde de 90'a ulaşmalılar.
                  </li>
                <li>
                  II- Kalite değerini 95'e yükseltmeliler.
                  </li>
                <li>
                  III- Kullanılabilirlik değerini 85'e yükseltmeliler.
                  </li>
              </ul>
              <Form.Control as="select" required
                value={answer3}
                name={'3. Soru'}
                onChange={handleChange}>
                <option>Seçiniz</option>
                <option>I ve II</option>
                <option>Yalnız II</option>
                <option>Yalnız I</option>
              </Form.Control>
            </Form.Group>



            <Form.Check
              onChange={handleChange}
              checked={checkboxChecked}
              value={checkboxChecked ? 'No' : 'YES'}
              name="subscribe"
              custom
              type='checkbox'
              id={`custom-${'checkbox'}`}
              label={<FormattedMessage id="form_subscribe_validations_checkbox" />}
            />
            <div className='contact-form-submit-button'>
              <Button type='submit' className='retmes-detail-button'><FormattedMessage id="contact_send" /></Button>
            </div>
          </Form>
        </Container>
      </section>
    </Layout>
  )
}

export default injectIntl(IsveZamanCampaign)
